import Article from '../layouts/Article';

const SpeechPage = () => {
  return (
    <Article>
      <h1>Course Description - Speech: Beginner, Intermediate, and Advanced</h1>
      <h2>Beginner Speech Class</h2>
      <p>
        This course is an introduction to speech communication which emphasizes
        the practical skill of public speaking, including techniques to lessen
        speaker anxiety and the memorization of a proven speech organization
        paradigm to enhance speaker presentations.
      </p>

      <p>
        The goal of this class is to prepare students for success in typical
        public speaking situations and to provide them with the basic principles
        of organization and research skills needed for effective speeches.
        Listening and critical thinking skills are emphasized.
      </p>

      <p>
        "Because life in postmodern America requires coping skills from all of
        us, we should not overlook the role of speech in public settings. In
        fact, Americans today seem to have only two real alternatives in coping
        with the challenges of the era – better speech or sullen silence."* The
        goal of this course is the former. *The Heritage of Rhetorical Theory,
        New York: McGraw Hill, 1997
      </p>
      <p>
        <span> “Communication is the most important skill in life.” </span>
      </p>
      <p>
        <span>~Stephen Covey</span>
      </p>

      <h2>Intermediate Speech Class:</h2>
      <p>
        This course is an intermediate level speech communication class which
        emphasizes the demonstrated ability to plan and present impromptu and
        prepared speeches, conduct research, synthesize the information, and
        present to targeted audiences.
      </p>
      <p>
        Using the principles of rhetoric as a guiding lens, this course allows
        students to gain practical experience in speech composition, delivery,
        and critique.
      </p>

      <p>
        The practice in evaluating historical and famous speeches, as well as
        those of their peers, is integral to this course.
      </p>

      <p>
        <span>
          “Speech is power: speech is to persuade, to convert, to compel.”
        </span>
      </p>
      <p>
        <span>~Ralph Waldo Emerson</span>
      </p>

      <h2>Advanced Speech Class:</h2>
      <p>
        This course focuses on the social and pragmatic aspects of public
        speaking as a communicative system, strategies of rhetorical theory, and
        the presentation of diverse forms of public communication. An advanced
        level of thinking, commitment to excellence, and class input are
        required. Advanced levels of thinking include:
      </p>
      <p>
        • the ability to apply communication principles in and out of classroom
        situations
      </p>
      <p>• rhetorical analysis</p>
      <p>
        • the ability to organize and arrange speech content in timed scenarios
      </p>
      <p>
        • the on-going improvement in producing unique and compelling
        communication messages
      </p>
      <p>
        This course prepares students for school and future career situations
        that necessitate organized, well reasoned, effective presentations that
        are analytical, and/or persuasive. Constant improvement in presentation
        is expected.
      </p>

      <p>
        <span>
          "In college and career, strong public speaking skills will set you
          apart ... and give you the ability to take on leadership roles."
        </span>
      </p>
      <p>
        <span>~Steve Bailey (BestColleges.com)</span>
      </p>

      <p>
        <span>
          These courses are beneficial in bolstering excellence in school
          settings. Learning, practicing, and honing speech skills provide a
          life-long advantage. It is important to begin early so that students
          gain confidence and expertise.
        </span>
      </p>

      <p>
        <span>
          “Let your speech be always with grace, seasoned with salt, that you
          may know how you ought to answer every man.”
        </span>
      </p>
      <p>
        <span>~Colossians</span>
      </p>
    </Article>
  );
};

export default SpeechPage;
