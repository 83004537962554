import classes from './Article.module.css';
// import Footer from './Footer';
import MainNav from './MainNav';

const Article = (props) => {
  return (
    <div>
      <MainNav />
      <div className={classes.article}>
        <div className={classes.content}>{props.children}</div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Article;
