import Article from '../layouts/Article';

const CurrentEventsPage = () => {
  return (
    <Article>
      <h1>Course Description - Current Events</h1>
      <h2>Course Description: </h2>
      <p>
        Using current events, this course focuses on world and local issues that
        affect students’ everyday lives, such as economics, government,
        geography, and demographics.
      </p>
      <p>
        This course uses newspapers, online media, cartoons, and newscasts to
        support class study, discussion, and homework.
      </p>
      <p>
        Additionally students participate in projects, presentations, and work
        with primary source materials and opinion pieces in order to better
        understand the world around them.
      </p>
      <p>
        Studying current events helps students understand the importance of
        people, events, and issues in the news. In addition, students build
        language, vocabulary, reading comprehension, critical thinking, problem
        solving, oral expression, and listening skills.
      </p>
      <p>
        <span>
          Incorporating current events into your child's studies adds real-world
          context to learning, creating deeper and more meaningful connections
          to many other school subjects.
        </span>
      </p>
    </Article>
  );
};

export default CurrentEventsPage;
