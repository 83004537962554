import classes from './Map.module.css';

const Map = () => {
  return (
    <div className={classes.container}>
      <iframe
        className={classes.iframe}
        title='Business Location'
        loading='lazy'
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJizZXuoE6R4YR6RYjo_c9ooI&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
      ></iframe>
    </div>
  );
};

export default Map;
