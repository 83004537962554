import classes from './Footer.module.css';

const Footer = () => {
  return (
    <div className={classes.footer}>
      {/* <p>
        © 2015- {new Date().getFullYear()} E3 Academics - All Rights Reserved.
      </p> */}
      <p>Designed by Chao Xu</p>{' '}
    </div>
  );
};

export default Footer;
