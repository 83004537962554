import Article from '../layouts/Article';
import zelle from '../../assets/pic/Pay_with_Zelle.jpg';

const HowtoPayPage = () => {
  return (
    <Article>
      <img src={zelle} alt='Pay with Zelle' />
    </Article>
  );
};

export default HowtoPayPage;
