import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./MobileNavSection.module.css";

const MobileNavSection = () => {
  return (
    <div className={styles.mobileNavSection}>
      <a
        href="http://www.thewoodlandschineseschool.org"
        rel="noreferrer"
        target="_blank"
        className={styles.btn}
      >
        林子中文学校
      </a>
      <a
        href="https://forms.gle/U2bUY6Mxjt9P9hA86"
        rel="noreferrer"
        target="_blank"
        className={styles.btn}
      >
        Register for a course
      </a>
      <a
        href="https://drive.google.com/file/d/1qBDdxpGl3U81MtlPAJClyaSYdOUHJNU4/view"
        rel="noreferrer"
        target="_blank"
        className={styles.btn}
      >
        Registration Form
      </a>
      <NavLink to="/howtopay" className={styles.btn}>
        How to Pay
      </NavLink>
    </div>
  );
};

export default MobileNavSection;
