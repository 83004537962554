import Article from '../layouts/Article';

const VocabularyPage = () => {
  return (
    <Article>
      <h1>Course Description - Advanced Vocabulary</h1>
      <p>
        <span>
          <strong>Our vocabulary classes are for advanced students.</strong>
        </span>
      </p>
      <p>
        <span>
          <strong>Course foundation: Greek and Latin roots</strong>
        </span>
      </p>

      <h2>GRADE 4</h2>

      <p>
        A thematically organized vocabulary program based on Greek and Latin
        roots. Words are presented with dictionary-style definitions, and all
        words are used in example sentences. Lists of "Familiar Words" and
        "Challenge Words" are provided for each root to help all students
        activate prior knowledge and keep advanced students on task. Exercises
        include matching synonyms, fill-in-the-blank, writing sentences, and
        more.
      </p>

      <h2>GRADE 5</h2>

      <p>
        Vocabulary from Classical Roots is a thematically-organized vocabulary
        program that systematically instructs students about Greek and Latin
        roots. Each lesson features highlighted roots with a variety of practice
        exercises that include working with synonyms, using words in context,
        using clues, and other related activities.
      </p>

      <h2>GRADE 6</h2>

      <p>
        A thematically organized vocabulary program based on Greek and Latin
        roots. Each lesson features the roots and key words derived from these
        roots with a review lesson after every third theme. Words are presented
        with dictionary-style definitions, and all words are used in example
        sentences. Lists of "Familiar Words'' and "Challenge Words" are provided
        for each root to help all students activate prior knowledge and keep
        advanced students on task.
      </p>

      <h2>GRADE 7+ Book A</h2>

      <p>
        A thematically organized vocabulary program based on Greek and Latin
        roots, each Vocabulary from Classical Roots lesson features roots and
        words derived from these roots. Each lesson is based on a specific theme
        such as "nature" or "the law." Words are presented with dictionary-style
        definitions, and all words are used in example sentences. Lists of
        "Familiar Words" and "Challenge Words" are provided for each root to
        help all students activate prior knowledge and keep advanced students on
        task. Exercises include matching synonyms, fill-in-the-blank, writing
        sentences, and more.
      </p>

      <h2>GRADE 8+ Book B</h2>

      <p>
        Vocabulary from Classical Roots teaches{' '}
        <strong>junior and senior high school students</strong> words derived
        from the most important Greek and Latin roots. Each lesson introduces
        words derived from two or more classical roots. A dictionary-like format
        provides all the necessary information, including related forms of the
        word and sentences illustrating each word's correct usage. Challenging
        exercises help students master the vocabulary they need to read works of
        literature and prepare for SATs. Exercises based on synonyms, antonyms,
        analogies, contextual vocabulary and sentence completions not only test
        students' mastery of definitions but also develop their familiarity with
        the format of comparable items.
      </p>

      <h2>GRADE 9+ Book C</h2>
      <p>
        The curriculum is derived from the most important Greek and Latin roots.
        Every lesson introduces words derived from two or more classical roots.
        A dictionary-like format provides all the necessary information,
        including related forms of the word and sentences illustrating each
        word's correct usage. Special sections called "Nota Bene" provide
        information about etymologies and words that might be confused.
        Drawings, illustrative sentences, and challenging exercises help
        students master the vocabulary they need to read works of literature and
        prepare for SAT's. Exercises based on synonyms, antonyms, analogies,
        vocabulary in context, and sentence completions not only test students'
        mastery of definitions but also develop their familiarity with the
        format of comparable items on standardized tests. Other exercises offer
        practice in recognizing correct usage.
      </p>

      <h2>GRADE 10+ Book D</h2>
      <p>
        A thematically organized vocabulary program based on Greek and Latin
        roots, each Vocabulary from Classical Roots lesson includes featured
        roots and words derived from these roots. Each lesson is based on a
        specific theme such as "nature" or "the law." Words are presented with
        dictionary-style definitions, and all words are used in example
        sentences. Lists of "Familiar Words" and "Challenge Words" are provided
        for each root to help all students activate prior knowledge and keep
        advanced students on task. Exercises include matching synonyms,
        fill-in-the-blank, writing sentences, and more.
      </p>

      <p>
        <strong>Nota Benes*</strong> are accessible throughout the course. Nota
        Benes provide etymologies and present commonly confused words. Review
        sections are also included. Book D also contains fill-in-the-blank
        passages that use the words in context. Word list included in the
        workbook.
      </p>

      <h2>GRADE 11 - 12 Book E (the most advanced level in this class)</h2>

      <p>
        A thematically organized vocabulary program based on Greek and Latin
        roots, each Vocabulary from Classical Roots lesson highlights featured
        roots and words that are derived from these roots. Each lesson is based
        on a specific theme such as "nature" or "the law." Words are presented
        with dictionary-style definitions, and all words are used in example
        sentences. Lists of "Familiar Words" and "Challenge Words" are provided
        for each root to help all students activate prior knowledge and keep
        advanced students on task. Exercises include matching synonyms,
        fill-in-the-blank, writing sentences, and more.
      </p>

      <p>
        <strong>Nota Benes*</strong> are accessible throughout the course. Nota
        Benes provide etymologies and present commonly confused words. Review
        sections are also included. Book E also contains fill-in-the-blank
        passages that use the words in context. Word list included in the
        workbook.
      </p>

      <p>
        <span>* no·ta be·ne (nōdə ˈbenē)</span>
      </p>
      <p>
        <span>
          a formal verb meaning to observe carefully or to take special notice
          (used in written text to draw attention to what follows)
        </span>
      </p>
    </Article>
  );
};

export default VocabularyPage;
