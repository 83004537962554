import Article from '../layouts/Article';

const StudySkillsPage = () => {
  return (
    <Article>
      <h1>Study Skills Class (19 super-powerful time management tips)</h1>

      <p>
        We teach these 19 super-powerful time management tips in our
        <strong> Study Skills Class. </strong>
        Peers provide accountability and positive peer pressure to make these
        powerful management solid habits.
      </p>
      <h2>1. Create a time audit.</h2>
      <p>
        When it comes to time management, the first step you need to take is
        finding out where your time actually goes. You may believe that you only
        send 30 minutes on TicToc, but in reality that is eating-up an hour and
        a half of your day.
      </p>
      <p>Consider tracking everything you do for a week.</p>
      <p>
        With this information, you can then make the appropriate adjustments.
      </p>
      <h2>2. Set a time limit/goal for each task.</h2>
      <p>
        Setting a time limit to each task prevents distractions and
        procrastination.
      </p>
      <p>
        This time limit habit can become a game which is usually much more
        motivating than a "nose to the grindstone" approach.
      </p>
      <p>
        Be sure to place buffers between tasks and activities. If a task is not
        completed on time, it can still be accomplished without eating into the
        time reserved for something else.
      </p>
      <h2>3. Use a to-do-list, but don’t abandon tasks.</h2>
      <p>
        <span>
          “All goals and projects are made up of smaller parts that need to be
          accomplished in order to achieve the goal, or complete the project.
          Create to-do lists for each goal and project, listing all the
          measurable steps that need to be accomplished,” suggests William
          Lipovsky.
        </span>
      </p>
      <p>
        <span>
          “Aside from keeping you focused, this also motivates you as you are
          able to see what you have already achieved, and what remains.”
        </span>
      </p>
      <p>
        At the same time, there will interruptions that may prevent you from
        completing a task. William recommends that you “make a point of always
        returning to and completing these tasks once you are able to. This may
        require you to set a limit on the number of tasks you are working on at
        any given time.”
      </p>
      <h2>4. Plan ahead.</h2>
      <p>
        One of the worst things that you can do is wake-up without a plan for
        the day. Instead of focusing on what needs to be done, you wander
        aimlessly and take care of more trivial matters.
      </p>
      <p>That’s why you should always plan ahead using one of these options;</p>
      <p>
        <strong>The night before.</strong>
      </p>
      <p>
        Before you go to bed, spend the last 15-minutes organizing for the next
        day. Compose a list of your most important items for tomorrow.
      </p>

      <p>
        <strong>First thing in the morning.</strong> During your morning routine
        write down the 3 or 4 most urgent and important matters that need to be
        addressed that day and work on those when you’re most productive.
      </p>
      <h2>5. Spend your mornings on MITs.</h2>
      <p>
        Mark Twain once said, "If it's your job to eat a frog, it's best to do
        it first thing in the morning. And If it's your job to eat two frogs,
        it's best to eat the biggest one first."
      </p>
      <p>
        Gross? Sure. But, the point that Twain was making that you should take
        care of your biggest and most-challenging tasks in the morning, aka your
        most important tasks (MITs) of the day.
      </p>
      <p>
        There are a couple reasons why this such an effective time management
        trick. For starters, you usually have the most amount of energy in the
        AM. So it’s better to tackle these tasks when you’re not drained. Also,
        you can use that feeling of accomplishment to get through the rest of
        the day.
      </p>
      <h2>6. Learn to ask for help.</h2>
      <p>
        Asking for help is very important - understanding assignments and
        formulas and homework means you will be more efficient in completing
        tasks and have more time to spend on more important things.
      </p>
      <h2>7. Eliminate half-work.</h2>
      <p>
        “In our age of constant distraction, it's stupidly easy to split our
        attention between what we should be doing and what society bombards us
        with,” writes James Clear.
      </p>
      <p>
        “Usually we're balancing the needs of messages, emails, and to–do lists
        at the same time that we are trying to get something accomplished. It's
        rare that we are fully engaged in the task at hand.”
      </p>
      <p>
        • James Clear has dubbed this “half–work” and here are a couple of
        examples;
      </p>
      <p>
        • You’re writing a report, but stop randomly to check your phone for no
        reason.
      </p>
      <p>
        • You try out a new workout routine, but switch to a new program a
        couple of days later because you read about it online.
      </p>
      <p>
        • While doing assigned reading, your mind wanders to the video game you
        love.
      </p>
      <p>
        Regardless of where and how you fall into the trap of half–work, the
        result is always the same: you're never fully engaged in the task at
        hand, you rarely commit to a task for extended periods of time, and it
        takes you twice as long to accomplish half as much. Block out
        significant time to focus on one project and eliminate everything else.
      </p>
      <h2>8. Change your schedule.</h2>

      <p>
        If you’re struggling with time management, the solution may be as simple
        as changing your schedule around.
      </p>
      <p>
        For example, instead of sleeping-in until 6:30am, wake-up an hour
        earlier. You may find that waking at 5:15am could give you time to
        exercise, plan-out your day, eat a nutritious breakfast, and even work
        on side projects without being disturbed.
      </p>
      <p>
        Also, consider waking-up earlier on the weekends and maybe cut-down on
        your amount of YouTube time.
      </p>
      <h2>9. Leave a buffer-time between tasks and meetings.</h2>
      <p>
        Jumping immediately from one task or meeting to the next may seem like a
        good use of your time, but it actually has the opposite effect. We need
        time to clear our minds and recharge by going for a walk, meditating, or
        just daydreaming. After all, the human brain can only focus for about
        90-minutes at a time.
      </p>
      <p>
        Without that break it’s more difficult to stay focused and motivated.
        Scheduling buffer-time also can prevent running late to your next class
        or appointment.
      </p>
      <h2>10. Get organized and single-task.</h2>
      <p>
        The average American spends 2.5 days each year looking for misplaced
        items. As a result, we spend over $2.7 billion annually in replacing
        these items. Instead of wasting both your time and money, get organized.
      </p>
      <p>
        Start by having a home for everything and making sure that items are put
        back where they belong. At the end of the day clean your work area and
        create a document management system.
      </p>
      <p>
        And, start single-tasking. Most people cite multitasking as the main
        culprit for misplacing items.
      </p>
      <h2>11. Follow the 80-20 rule.</h2>
      <p>
        “The Pareto Principle also known as the 80-20 rule suggests that 80% of
        results come from 20% of the effort put in. This is commonly used in
        sales as 80% of sales typically come from 20% of the customers,” writes
        Renzo Costarella.
      </p>
      <p>
        “When it comes to how you should manage your time this principle can
        also be applied. 80% of your results comes from 20% of your actions.”
      </p>
      <p>
        Renzo suggests that you start by looking “at your schedule or to-do-list
        every day. For the sake of simplicity try to get down five tasks you
        need to accomplish. Using the principle you can probably eliminate the
        majority of the items on your list. It may feel unnatural at first but
        overtime this will condition you to scale up effort on the most
        important tasks.”
      </p>
      <h2>12. Use a calendar.</h2>
      <p>
        Calendars have long been a fundamental tool for time management. If you
        do not already use a calendar - start today!
      </p>
      <h2>13. Stop being perfect.</h2>
      <p>
        When you’re a perfectionist, nothing will ever be good enough. That
        means you’ll keep going back to same task over and over again. How
        productive do you think your day will be as a result?
      </p>
      <p>
        So, stop being perfect. It doesn’t exist. Do the best you can and move
        on.
      </p>
      <h2>14. Just say “No.”</h2>
      <p>You can only handle so much.</p>
      <p>
        If you already have a full plate then decline that party invitation or
        helping your classmate on a project until you have the spare time.
      </p>
      <h2>15. Instill keystone habits.</h2>
      <p>
        Charles Duhigg, author of "The Power of Habit," coined the term
        "keystone habits." But, what are they? Simply put, they’re habits that
        can transform your life, such as exercising, tracking what you eat,
        developing daily routines, and praying.
      </p>
      <p>
        These habits replace bad habits and solicit other good habits. As a
        result, you’ll be healthier, more focused, and better suited to manage
        your time.
      </p>
      <h2>16. Don’t waste time waiting.</h2>
      <p>Waiting is time that could be better spent elsewhere.</p>
      <p>
        However, instead of wasting this “waiting” time, make the best of it.
      </p>
      <p>
        For example, while sitting in a waiting room consider reading an
        inspirational book, listening to a podcast, or planning an upcoming
        project.
      </p>
      <h2>17. Find inspiration.</h2>
      <p>
        When I’m dragging, I use inspirational sources like extra prayer, a TED
        Talk, or biography. It’s a simple way to reignite that fire to get me
        motivated and back-on-track.
      </p>
      <h2>18. Batch similar tasks together.</h2>
      <p>
        When you have related work, batch them together. For example, don’t
        answer your emails and phone calls throughout the day. Schedule a
        specific time to handle these tasks.
      </p>
      <p>
        The reason? Different tasks demand different types of thinking. By
        batching related tasks together, your brain isn’t switching gears -
        which means you cut out that time reorienting.
      </p>
      <h2>19. Do less.</h2>
      <p>
        This is a tactic from Leo Babauta. He started the blog Zen Habits and
        it’s definitely a must read. So, what does Leo have to say about doing
        less.
      </p>
      <p>
        Doing less doesn’t mean “less is more.” It means “less is better.” This
        is achieved by slowing down, being aware of what needs to be done, and
        concentrating only on those things. Once you do, make every action
        count. As a result, you’ll be creating more value instead of just
        fodder.
      </p>
    </Article>
  );
};

export default StudySkillsPage;
