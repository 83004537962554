import { Link } from 'react-router-dom';
import classes from './E3header.module.css';

const E3header = () => {
  return (
    <div className={classes[`container`]}>
      <div className={classes[`text-box`]}>
        <h1 className={classes[`title-primary`]}>E3 Academics</h1>
        <p className={classes[`title-description`]}>
          Excellence in Educational Enrichment
        </p>
        <Link to='/article/forthelove' className={classes.btn}>
          For the Love of Learning
        </Link>
      </div>
    </div>
  );
};

export default E3header;
