import classes from './Gallery.module.css';

import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G1-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G1-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G2-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G2-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G3-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G3-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G4-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G4-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G5-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G5-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G6-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G6-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G7-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G7-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G8-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G8-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G9-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G9-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G10-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G10-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G11-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G11-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G12-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G12-T.jpg`,
  },
  {
    original: `${process.env.REACT_APP_GALLERY_URL}/G13-L.jpg`,
    thumbnail: `${process.env.REACT_APP_GALLERY_URL}/G13-T.jpg`,
  },
];

const Gallery = () => {
  return (
    <div className={classes.container}>
      <div className={classes['gallery-wrapper']}>
        <ImageGallery items={images} />
      </div>
    </div>
  );
};

export default Gallery;
