import Article from '../layouts/Article';

const SciencePage = () => {
  return (
    <Article>
      <h1>Course Description - Science</h1>
      <h2>Grades 4 - 6 (1 semester)</h2>
      <h2>ANATOMY</h2>
      <p>
        Students will have the opportunity to learn about the skeleton and
        master the bones in the human body. They will enjoy learning about the
        other important systems in the human body such as the respiratory system
        and the digestive system. The class will also learn about specific
        organs and their function.
      </p>
      <h2>Grades 4 - 6 (2 semesters)</h2>
      <h2>EARTH SCIENCE AND ECOLOGY</h2>
      <p>
        <strong>EARTH SCIENCE.</strong> What is earth science? What categories
        are there? What are the names of the experts? Students enjoy learning
        about pyroclastic materials, their make-up, and what contributes to
        their fluid nature. Your child will understand Pangaea, the theory of
        continental drift, and the supercontinent. Your student may enjoy
        learning about Compressional stress, Tensional stress, Shear stress on
        the surface of the earth, and more!
      </p>
      <p>
        <strong>ECOLOGY.</strong> The class will also be introduced to ecology
        and discover how organisms interact with the environment around them and
        how it affects the world. We learn about introduced species, invasive
        species, mutualism, commensalism, and parasitism. Do you ever wonder why
        some animals look or act the way they do and how these things relate to
        the predator/prey relationship? We will dive into offensive strategies
        of predators, chemical warfare, coloration, Batesian mimicry and more.
        We will develop an understanding of carrying capacity and dispersion and
        more!
      </p>
    </Article>
  );
};

export default SciencePage;
