import Article from '../layouts/Article';

// import WritersMarkPage from './WritersMarkPage';

const WritingPage = () => {
  return (
    <Article>
      <h1>
        Writer’s Mark Studio Classes utilize the best of four curricula. (4th
        -12th Grade)
      </h1>
      <p>
        <span>1) IEW’s Excellence in Writing</span>
      </p>
      <p>
        <span>2) Editor-in-Chief</span>
      </p>
      <p>
        <span>3) Seton Grammar</span>
      </p>
      <p>
        <span>4) Writer’s Mark -Tusal Writing Method</span>
      </p>
      <p>
        Excellence in Writing successfully equips students with tools to
        significantly improve their writing skills. Its methods not only build
        written and oral communication skills, but also improve critical
        thinking.
      </p>
      <p>
        Editor-in-Chief improves students' grammar, punctuation, spelling,
        capitalization, and attention to detail using a standards-based thinking
        approach.
      </p>
      <p>
        Seton Grammar guides students through the study of nouns, pronouns,
        adjectives, verbs, adverbs, prepositions, conjunctions, interjections,
        participles, gerunds, infinitives, clauses, and sentences.
      </p>
      <p>
        Tusal Writing Method teaches simple through advanced Rhetorical Devices.
      </p>
      <p>
        Critical writing tools are identified and practiced in order to improve
        students' understanding and subsequent application of these devices.
      </p>
      <p>
        The SAT and ACT both test knowledge of these Rhetorical and Literary
        Devices. It is never too early to become familiar with them.
      </p>
      <p>
        Writer’s Mark Studio Class methods can also be used across the
        curriculum to reinforce content area. Students learn to write as they
        write to learn.
      </p>
      <p>
        With diligent and consistent effort there is a wonderful transformation
        from immature or even reluctant writers to competent, confident
        communicators.
      </p>
      <p>
        It is possible to teach students with very high writing aptitude
        alongside those with undeveloped writing aptitude, and the system works
        magnificently at both ends of that spectrum.
      </p>
      <p>
        Lessons in our class are carefully planned to be steps toward more
        sophisticated writing. Lessons build from week to week and each lesson
        is prepared with differing levels of challenge in order to accommodate
        expertise levels.
      </p>
    </Article>
  );
};

export default WritingPage;
