import Article from '../layouts/Article';

const ForthelovePage = () => {
  return (
    <Article>
      <h1>Fostering a LOVE of Learning - 6 Steps</h1>
      <p>
        When you foster a love of learning in your children, you place them on a
        path of happiness and success. All children are born with a drive to
        learn and explore the world around them. All teachers and parents have
        the ability to nurture a desire for continuous learning. Here are six
        simple strategies.
      </p>
      <h2>1. Help your children become strong readers.</h2>
      <p>
        Children are never too young or old to enjoy a story. Begin reading to
        your kids when they are babies and encourage reading throughout their
        lives. <strong>At E3 we support this reading habit!</strong> Our
        students read an average of a book a week - in addition to school
        reading assignments. Reading and reading comprehension are skills that
        require practice. That practice needs to be consistent and supported.
      </p>
      <p>
        <span>
          There are many little ways to enlarge your child’s world. Love of
          books is the best of all.
        </span>
      </p>
      <p>
        <span>–Jacqueline Kennedy Onassis </span>
      </p>
      <h2>2. Share with your children how and why you are inspired. </h2>
      <p>
        Sometimes we think that kids will just "get it." Most often, this is not
        the case. Tell your kids how YOU are inspired by learning. Listen to
        their stories of trials and success.
        <strong> At E3 our students know that learning is important.</strong>
        Hard work pays off. Good habits reap rewards.
      </p>
      <h2>3. Children need to learn to make good academic choices. </h2>
      <p>
        By default, children need choices. When children have a choice, they
        feel a sense of ownership and their work reflects that. Within
        acceptable perimeters, we let them choose their own books, writing
        prompts, and learning games.
        <strong>E3 fosters academic maturity.</strong>
      </p>
      <h2>4. Show Unconditional academic support.</h2>
      <p>
        Even if your child chooses a weekly book that is not your favorite, as
        long as it is appropriate, continue to encourage your child. There are
        52 weeks in a year - that provides an opportunity for 51 more books.
        <strong>
          We promise to steer kids towards good and great literature.
        </strong>
        Let them have an easier read or a "just for fun" book once in awhile.
      </p>
      <p>
        <span>
          A good novel tells us the truth about its hero; but a bad novel tells
          us the truth about its author.
        </span>
      </p>
      <p>
        <span>–Gilbert K. Chesterton</span>
      </p>
      <h2>5. Conversations with a purpose.</h2>
      <p>
        Have lots of conversations with your kids about what they are learning
        without turning the conversation into a test.
        <strong>
          At E3 we work on reading, writing, vocabulary, math, Spanish,
          spelling, test taking skills, speech, and debate
        </strong>
        - there is a lot to talk about. Let your kids teach you. Let them take
        you on their journey. Maybe you already know the Latin root word, the
        math formula, or the punctuation rule -- no matter. Rejoice in their
        discovery or mastery of a learning benchmark.
      </p>
      <h2>
        6. Provide resources for learning so that it is the norm and even
        "cool."
      </h2>
      <p>
        <strong>E3 is one of the best academic resources you will find.</strong>
        We focus on your child's academic needs and goals. Our students are
        proud of their hard work and enjoy congenial competition with their
        peers.
      </p>
    </Article>
  );
};

export default ForthelovePage;
