import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
// import AuthContext from '../../store/auth-context';

// import Clock from '../ui/Clock';
import { Backdrop } from "../ui/ErrorModal";

import classes from "./MainNav.module.css";
import { BsList } from "react-icons/bs";
import logo from "../../assets/pic/E3_logo.png";

const MainNav = () => {
  // const authCtx = useContext(AuthContext);

  // const { isLoggedIn, isAdmin, username } = authCtx;
  const [showNav, setShowNav] = useState(false);

  // const onLogoutHandler = () => {
  //   setShowNav(false);
  //   authCtx.logout();
  // };

  const onLinkClickHandler = () => {
    setShowNav(false);
  };

  const onToggleHandler = () => {
    setShowNav((prev) => !prev);
  };

  return (
    <header className={classes.header}>
      <nav>
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="Company Logo" />
          </Link>
        </div>
        <div className={classes.cname}>
          <Link to="/">E3 ACADEMICS</Link>
        </div>
        <BsList
          className={`${classes.header__menuicon} ${
            showNav ? classes.active : ""
          }`}
          onClick={onToggleHandler}
        />
        {showNav && <Backdrop onConfirm={onLinkClickHandler}></Backdrop>}
        <div
          className={classes.header__links}
          id={showNav ? classes.hidden : ""}
        >
          <a
            href="http://www.thewoodlandschineseschool.org"
            rel="noreferrer"
            target="_blank"
          >
            林子中文学校
          </a>
          {/* <a
            href="https://drive.google.com/file/d/1T-HDbD249CpOKW7W0jJIlv0m_g_5wH7G/view?usp=sharing"
            rel="noreferrer"
            target="_blank"
          >
            Summer Camp
          </a> */}
          <a
            href="https://forms.gle/U2bUY6Mxjt9P9hA86"
            rel="noreferrer"
            target="_blank"
          >
            Register for a course
          </a>
          {/* <NavLink onClick={onLinkClickHandler} to='/register'>
            Register for a course
          </NavLink> */}
          <a
            href="https://drive.google.com/file/d/1qBDdxpGl3U81MtlPAJClyaSYdOUHJNU4/view"
            rel="noreferrer"
            target="_blank"
          >
            Registration Form
          </a>
          <NavLink onClick={onLinkClickHandler} to="/howtopay">
            How to Pay
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default MainNav;
