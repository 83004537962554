// import Footer from '../layouts/Footer';
// import MainNav from '../layouts/MainNav';
// import classes from './RegisterPage.module.css';
import Article from '../layouts/Article';

const RegisterPage = () => {
  return (
    <Article>
      {/* <iframe src='https://forms.gle/6KaEZyRvywEJmooJ8' title='Google Forms'>
        Loading…
      </iframe> */}
      <iframe
        src='https://docs.google.com/forms/d/e/1FAIpQLScYZy3WcaSVticcWmHDP9O2trGUzfnIglGN6RiGHiFCaexZIA/viewform?embedded=true'
        width='640'
        height='3625'
        frameborder='0'
        marginheight='0'
        marginwidth='0'
        title='Google Forms'
      >
        Loading…
      </iframe>
    </Article>
  );
};

export default RegisterPage;
