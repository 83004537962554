import Article from '../layouts/Article';

const DebatePage = () => {
  return (
    <Article>
      <h1>E3 Debate</h1>
      <p>
        Our debate program offers unique educational opportunities that promote
        organizational skills, logical argumentation, respectful refutation, and
        effective delivery. Debate is a valuable activity for students of all
        skill levels. It builds public speaking confidence and strong academic
        rigor.
      </p>
      <p>Here are 12 ways that debating can help students for years to come.</p>

      <h2>1. Critical Thinking</h2>

      <p>
        Debating helps to develop essential critical thinking skills – the
        ability to make reasoned and well thought out arguments in addition to
        questioning the evidence behind a particular stance or conclusion.{' '}
      </p>

      <h2>2. Articulation and Organization </h2>

      <p>
        From explaining complex mathematical equations to the plot of a favorite
        book, debating helps students to organize thoughts, identify an
        audience, and choose the appropriate tone and words. The ability to
        articulate and plan thoughts is key in debating, producing a well-
        planned out and sharp argument.{' '}
      </p>

      <h2>3. Education is key</h2>

      <p>
        Have you done your research? Do you understand your topic? Are your
        arguments coherent? Do they make sense?
      </p>

      <p>
        A good debater reads and finds sources, writes and drafts speeches,
        speaks confidently, and is adept at listening. These are skills that are
        useful both inside and outside the classroom.{' '}
      </p>

      <h2>4. Think on your feet</h2>

      <p>
        To "think on your feet" is the ability to react decisively, effectively,
        and without prior planning. Debaters learn and hone this skill. They are
        also able to identify any loopholes in counter-arguments. The ability to
        think on your feet and respond to questions and comments is essential in
        any situation.
      </p>

      <h2>5. Conflict resolution</h2>

      <p>
        Debaters are trained to spot and resolve conflicts before they impact a
        discussion. Debaters also are encouraged to accept a loss graciously.
        These are both important skills in debating and in life.{' '}
      </p>

      <h2>6. Empathy</h2>

      <p>
        A successful speaker will always maintain a degree of empathy while
        debating. It is not necessary to agree with the other party’s argument,
        but empathy is an important life skill, helping you to improve your
        relations on a personal and professional level.
      </p>
      <h2>7. Keep your emotions in check</h2>

      <p>
        A good debater will also remember to retain an element of poise and
        composure – addressing the issue rather than getting carried away
        emotionally. Managing your emotions will not only stand you in good
        stead while debating. It is no easy skill to manage, but will gain you
        respect in every walk of life.
      </p>

      <h2>8. Construct meaning out of complex situations</h2>

      <p>
        Debating will also helps students to learn how to construct meaning out
        of, what are usually, rather complex topics. From world peace to banning
        nuclear energy, taking the time to research the subject and carefully
        construct the points you want to make will help you construct a better
        argument.
      </p>

      <h2>9. Presentation skills</h2>

      <p>
        Presentation plays a crucial part in how others perceive you as an
        individual, and more importantly, as competition. Good presentation
        skills will earn you instant respect both while debating and in future
        situations.
      </p>

      <h2>10. Confidence boost</h2>

      <p>
        Good presentation skills will also help boost confidence. This helps you
        shine at interviews or work related events. A polished overall
        presentation and confident speaking manner will make you a memorable
        individual.
      </p>

      <h2>11. Be more socially conscious</h2>

      <p>
        Debating is a skill which requires students to be much more politically
        and socially aware of current affairs. Being more socially and
        politically aware is not only important while debating but it will also
        ensure that you connect and relate better to others, both socially and
        in the workplace.
      </p>

      <h2>12. An expanded worldview</h2>

      <p>
        Debating provides the opportunity for students to study topics that
        provide a deeper understanding of the society and the world in which we
        live. An expanded worldview also encourages students to be proud of
        their talents and achievements, in addition to being more appreciative
        of the opportunities that await them.
      </p>
      <br />
      <br />
      <p>
        <span>Sources</span>
      </p>
      <p>
        <span>E3 Debate Instructors</span>
      </p>
      <p>
        <span>St John’s-Ravenscourt School World Champion Debaters</span>
      </p>
      <p>
        <span>
          Study International -Debating-will-help-you-for-the-rest-of-your-life
        </span>
      </p>
    </Article>
  );
};

export default DebatePage;
