import Article from '../layouts/Article';

const WritersMarkPage = () => {
  return (
    <Article>
      <h1>Course Description - Writer's Mark®</h1>
      <h2>Writers Mark Foundation (younger beginners)</h2>

      <h2>Writers Mark Cornerstone (5th, 6th, 7th grade)</h2>
      <h2>Writers Mark Honors (6th, 7th, 8th grade)</h2>
      <h2>Writers Mark AP (8th - High School)</h2>
      <p>
        <strong>Writers Mark® </strong>successfully equips students with tools
        to significantly improve their writing skills. Its methods not only
        build written and oral communication skills, but also improve critical
        thinking. Weekly essays are an integral part of the Writers Mark
        curriculum. Completed essays culminate in an end-of-the-year portfolio.
      </p>

      <p>
        <strong>Writers Mark® </strong>teaches essential outlining, composition,
        and proof-reading strategies such as: keyword outlining, stylistic
        techniques, rhetorical devices, grammar, punctuation, and editing.
      </p>

      <p>
        These critical writing tools are defined and practiced in order to
        improve students' understanding and subsequent application of these
        devices.*
      </p>

      <h2>Writers Mark® Essays</h2>
      <p>Narrative - tells a story/conveys an experience (4 types)</p>

      <p>Descriptive - creates an image</p>
      <p>
        Expository - explains, informs, describes a topic in a clear, correct
        fashion (analytical)
      </p>

      <p>Compare & Contrast - a type of analytical + descriptive essay</p>

      <p>Persuasive/Argumentative</p>

      <p>Research - a type of informative essay</p>

      <p>Literary Analysis</p>

      <p>Personal Improvement - resume, letters</p>

      <p>
        <span>
          * The SAT and ACT both test knowledge of these rhetorical and literary
          devices.
        </span>
      </p>
      <p>
        <span>It is never too early to become familiar with them.</span>
      </p>
    </Article>
  );
};

export default WritersMarkPage;
