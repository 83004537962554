import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import classes from './CTA.module.css';
import { TfiEmail } from 'react-icons/tfi';
import { BiPhoneCall } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const CTA = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`,
        `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          // console.log(result.text);
          e.target.reset();
          alert(
            'Your message has been sent. We will reply to you as soon as possible.'
          );
        },
        (error) => {
          // console.log(error.text);
          alert('Oooops! Failed to send message. Please try again later.');
        }
      );
  };

  return (
    <div className={classes.container}>
      <div className={classes['contact-wrapper']}>
        <Link
          className={classes.emaillink}
          to='#'
          onClick={(e) => {
            window.location.href = 'mailto:E3Academics@yahoo.com';
            e.preventDefault();
          }}
        >
          <div className={classes['contact-content']}>
            <TfiEmail />
            <span>Email Us</span>
            <span>E3Academics@yahoo.com</span>
          </div>
        </Link>

        <Link
          className={classes.calllink}
          to='#'
          onClick={(e) => {
            window.location.href = 'tel:832-790-5242';
            e.preventDefault();
          }}
        >
          <div className={classes['contact-content']}>
            <BiPhoneCall />
            <span>Call Us</span>
            <span>832-790-5242</span>
          </div>
        </Link>
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type='text' name='user_name' />
        <label>Email</label>
        <input type='email' name='user_email' />
        <label>Message</label>
        <textarea name='message' />
        <input type='submit' value='Send' />
      </form>
    </div>
  );
};

export default CTA;
