import Article from '../layouts/Article';

const SpanishPage = () => {
  return (
    <Article>
      <h1>Español @ E3</h1>
      <h2>SPANISH</h2>
      <p>
        <span>
          Speaking Spanish greatly increases chances for college scholarships
          and employment opportunities.
        </span>
      </p>
      <p>
        <span>
          French, Italian, Romanian, and Portuguese are much easier to learn for
          those who know Spanish
        </span>
      </p>
      <p>
        <span>These "Romance languages" originate from Latin.</span>
      </p>
      <p>
        <span>
          Chinese is a tonal language and English is a Germanic language.
        </span>
      </p>
      <p>
        <strong>Spanish I</strong> is an introductory level course in Spanish.
        It is designed for students with little or no previous study of Spanish.
        This course teaches basic language patterns and vocabulary.
      </p>
      <p>
        Repetition, memorization, recitation, and class participation are
        integral components of this course. The focus is on all four-language
        skills listening, speaking, reading and writing.
      </p>
      <p>
        Proficiency is achieved through the use of media, readings, worksheets,
        homework, and class discussions.
      </p>
      <p>
        Active participation is required in this course because it contributes
        to the student's confidence and retention. Homework is reviewed in class
        and classes are accumulative. It is essential that students complete
        homework and practice on a daily basis (~approximately 10-15 minutes a
        day). This enables students to participate in class in a meaningful way.
      </p>
      <p>
        <strong>Spanish II</strong> builds on all previous knowledge and skills
        obtained in beginning Spanish classes. A deeper immersion into
        conversation will be an integral part of this class. Spanish II furthers
        the study of grammar, vocabulary and sentence structure. Students will
        work to improve listening, speaking, reading and writing skills.
        Students begin to develop reading comprehension skills through
        literature. Additionally, cultural components necessary to the
        understanding of the language are introduced in Spanish II.
      </p>
      <p>
        Active Participation is required in this course. Homework is reviewed in
        class and classes are accumulative. It is important that students keep
        pace with the lessons. This enables students to become familiar with
        Spanish and participate in class in a meaningful way.
      </p>
      <p>
        <strong>Spanish III</strong> is a complete immersion into the language.
        The class is designed to expand and consolidate the student’s lexical
        and grammatical understanding of the language. Abundant and diverse
        vocabulary acquisition is one of the most important goals of this class.
        Students will work toward mastery of basic grammatical structures.
        Readings in classic literature, essays in Spanish, and Spanish film are
        the diverse teaching platforms used in Spanish III. The ability to
        communicate effectively in Spanish in any situation is the end goal.
      </p>
      <p>
        Active Participation is required in this course. Homework is reviewed in
        class and classes are accumulative. It is important that students keep
        pace with the lessons.
      </p>
    </Article>
  );
};

export default SpanishPage;
