import Article from '../layouts/Article';

const ReadingPage = () => {
  return (
    <Article>
      <h1>E3 Core Reading Program & Reading Laboratory</h1>
      <h2>Core Reading Program - 1, 2, 3!</h2>
      <p>
        Independent reading achievement directly correlates with success in
        school.
      </p>
      <p>
        1) <strong>Read a book a week</strong> (every other week for older
        students).
      </p>
      <p>
        2) <strong>Write a Book Review</strong> with key information: author,
        illustrator, date of publication, awards, characters, setting, conflict
        and resolution, and vocabulary words
      </p>
      <p>
        3) At the end of the year, students will have an impressive
        <strong> Portfolio of Book Reviews</strong> for future reference.
      </p>
      <p>
        In 2020, students who successfully complete 40 book reviews will receive
        the<strong> E3 Reading Award of Excellence</strong> and will be
        recognized on our website.
      </p>
      <h2>SRA Reading Laboratory</h2>
      <p>
        This curricula is filled with reading selections which are assigned a
        particular color, based on developmental milestones.
      </p>
      <p>
        Comprehension question are answered after each story. Successfully
        moving through these reading exercises helps students to hone the skills
        necessary to excel in school.
      </p>
      <p>Recommended by Science Research Associates and McGraw-Hill</p>
      <p>
        <span>Reading maketh a full man. ~Sir Francis Bacon</span>
      </p>
    </Article>
  );
};

export default ReadingPage;
