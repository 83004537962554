import imgSmileFace from '../../assets/pic/kat_smiling.jpeg';
import classes from './AboutE3.module.css';

const AboutE3 = () => {
  return (
    <div className={classes.container}>
      <div className={classes.about}>
        <img src={imgSmileFace} alt='smile' />
        <div>
          <h1 className={classes['heading-primary']}>ABOUT E3 ACADEMICS</h1>
          <p className={classes['about-description']}>
            We offer personal one-to-one tutoring, dynamic small group settings,
            classes, after school programs, web-based tutoring, and seasonal
            camps.
          </p>
          <p className={classes['about-description']}>
            Pick up available for After School Program.
          </p>
          <p className={classes['about-description']}>
            All classes are online or in person.
          </p>
          <p className={classes['about-description']}>
            Our International Program connects us with students all over the
            world, &amp; our home base is in the beautiful town of The
            Woodlands, Texas.
          </p>
          <p className={classes['about-description']}>
            We meet students where they are. An individualized academic strategy
            is created and implemented. Strong and consistent work helps
            students soar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutE3;
